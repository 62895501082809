import React from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Bubble } from 'react-chartjs-2'
import { connect } from 'react-redux'

import lang from 'lang'

import backgroundImage from './mood-meter-chart-background.svg'

const regExpNumbers = /-?[0-9]/g

const defaultChartData = {
  datasets: [
    {
      label: 'moods',
      fill: true,
      backgroundColor: '#fff',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#fff',
      pointRadius: 10,
      pointBorderWidth: 10,
      pointHoverRadius: 10,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 10,
      pointHitRadius: 10,
      data: [
        // Here we'll populate moods after some preparation
        // and pass that to the chart
      ],
    },
  ],
}

const max = 5
const min = -5

const defaultAxesOptions = (labelString) => [
  {
    scaleLabel: {
      labelString,
      display: true,
      fontStyle: 'bold',
      fontSize: 14,
    },
    gridLines: {
      display: false,
    },
    ticks: {
      max,
      min,
      stepSize: 1,
    },
  },
]

const calculatePointRadius = (occurances) => {
  const baseRadius = 15
  let computedRadius = occurances + baseRadius
  if (computedRadius > 20) {
    computedRadius = 20
  } else if (computedRadius < 10) {
    computedRadius = 10
  }
  return computedRadius
}

@connect((state) => ({
  energyLevel: state.moodMeter.forms.plot.data.energy,
  pleasantnessLevel: state.moodMeter.forms.plot.data.pleasantness,
}))
class MoodMeterChart extends React.Component {
  static propTypes = {
    // We're either plotting multiple moods or just one
    moods: PropTypes.array,
    mood: PropTypes.object,
    withoutAxesLabels: PropTypes.bool,
    allowDragging: PropTypes.bool,
    updateEnergy: PropTypes.func,
    updatePleasantness: PropTypes.func,
    energyLevel: PropTypes.number.isRequired,
    pleasantnessLevel: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.groupedMoodsByOccurance = {}
    this._chartRef = React.createRef()
    this.state = {
      dragX: null,
      dragY: null,
      dragPleasantness: 0,
      dragEnergy: 0,
      bubbleHovered: false,
    }
  }

  componentDidMount() {
    this.image = new Image()
    this.image.src = backgroundImage.src
  }

  handleDragStart() {
    return (e) => {
      const point = this._chartRef.current.chartInstance.getElementsAtEvent(e)
      if (point.length) {
        this.setState({
          dragX: e.x,
          dragY: e.y,
          dragPleasantness: this.props.pleasantnessLevel,
          dragEnergy: this.props.energyLevel,
        })
      }
    }
  }

  handleClick(chartInstance) {
    return (e) => {
      if (_.isNull(this.state.dragX) || _.isNull(this.state.dragY)) {
        // The size of a "square" is all the space for the "chart" divided by the number of "squares"
        const squareSide = (chartInstance.chartArea.bottom - chartInstance.chartArea.top) / 10

        // Offset is the X and Y coordinates between the mouse pointer and the event and the padding edge of the target node.
        // ChartArea are the coordinates in the canvas where the chart(the MM background) is drawn.
        const pleasantness = Math.round((e.offsetX - chartInstance.chartArea.left) / squareSide) - 5
        const energy = -Math.round((e.offsetY - chartInstance.chartArea.top) / squareSide) + 5

        if (pleasantness >= min && pleasantness <= max) {
          this.props.updatePleasantness(pleasantness)
        }
        if (energy >= min && energy <= max) {
          this.props.updateEnergy(energy)
        }
      }
    }
  }

  handleDragEnd() {
    return () => {
      this.setState({ dragX: null, dragY: null })
    }
  }

  handleDragging() {
    return (e) => {
      if (_.isNull(this.state.dragX) || _.isNull(this.state.dragY)) {
        return
      }
      const pleasantness = this.state.dragPleasantness + Math.round((e.x - this.state.dragX) / 45)
      const energy = this.state.dragEnergy + Math.round((this.state.dragY - e.y) / 45)
      if (pleasantness >= min && pleasantness <= max) {
        this.props.updatePleasantness(pleasantness)
      }
      if (energy >= min && energy <= max) {
        this.props.updateEnergy(energy)
      }
    }
  }

  preparePropsForCharting() {
    const data = _.clone(defaultChartData)
    if (_.isArray(this.props.moods)) {
      // We're plotting multiple moods
      // So we need to create a hash-map of point coordinates and moods in that point:
      // {
      //   "x-y": [{moodObject}, {...}]
      // }
      this.groupedMoodsByOccurance = _.groupBy(
        this.props.moods,
        (mood) => `${mood.pleasantness}-${mood.energy}`,
      )
      data.datasets[0].data = _.toArray(
        _.map(this.groupedMoodsByOccurance, (mood, index) => {
          return {
            x: mood[0].pleasantness,
            y: mood[0].energy,
            r: calculatePointRadius(this.groupedMoodsByOccurance[index].length),
          }
        }),
      )
      return data
    }
    // If we're not plotting multiple moods we can just show a regular data point
    data.datasets[0].data = [
      {
        x: this.props.mood.pleasantness,
        y: this.props.mood.energy,
        r: 15,
      },
    ]
    return data
  }

  renderTooltipContent = (bodyItem) => {
    // We're parsing "moods: (1,-2,10)" in order to get all moods for a specific X,Y point
    // First match is our X scale value
    // Second match is our Y scale value
    // We disregard the third and fourth matches ("1" and "0" -> "10") because this is the radius
    const [parsedX, parsedY] = bodyItem.lines[0].match(regExpNumbers)
    const moods = this.groupedMoodsByOccurance[`${parsedX}-${parsedY}`]
    // We want to render for each point the types of moods plotted there
    const innerHtml = _.join(
      _.map(moods, (mood) => {
        const createdAt = format(new Date(mood.created_at), 'MM/dd/yyyy, h:mm a').toLowerCase()
        return `<span><strong>${mood.emotion_words.name}</strong> - ${createdAt}</span>`
      }),
      '<br/>',
    )
    return innerHtml
  }

  renderCustomTooltip = (tooltipModel) => {
    if (_.isArray(this.props.moods)) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.innerHTML = '<table></table>'
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      // Set Text
      if (tooltipModel.body) {
        const moodsHtml = tooltipModel.body.map(this.renderTooltipContent)
        const innerHtml = `
          <tbody>
            ${_.map(
              moodsHtml,
              (moodHtml) => `
                <tr>
                  <td style="
                    border-width: 2px;">

                      ${moodHtml}

                  </td>
                </tr>
              `,
            )}
          </tbody>
        `

        const tableRoot = tooltipEl.querySelector('table')
        tableRoot.innerHTML = innerHtml
      }

      const position = this._chartRef.current.chartInstance.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.opacity = 1
      tooltipEl.style.position = 'fixed'
      tooltipEl.style.left = `${position.left + tooltipModel.caretX}px`
      tooltipEl.style.top = `${position.top + tooltipModel.caretY}px`
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
    }
  }

  render() {
    const data = this.preparePropsForCharting()
    return (
      <div
        className={cn({ hovered: this.state.bubbleHovered })}
        onMouseLeave={() => {
          const tooltipEl = document.getElementById('chartjs-tooltip')
          if (tooltipEl) {
            tooltipEl.style.opacity = 0
          }
        }}>
        <Bubble
          ref={this._chartRef}
          data={data}
          // Add some instance specific plugins
          plugins={[
            {
              beforeInit: (chartInstance) => {
                // Passing aspectRatio in options doesn't change the ratio of the chartInstance
                // that's why we're doing it here
                chartInstance.chart.aspectRatio = 1
              },
              beforeDraw: (chartInstance) => {
                const { ctx } = chartInstance.chart
                // Draw the background image
                ctx.drawImage(
                  this.image,
                  0,
                  0,
                  this.image.width,
                  this.image.height,
                  // Positions the image scaled in the chartArea
                  chartInstance.chartArea.left,
                  chartInstance.chartArea.top,
                  chartInstance.chartArea.right - chartInstance.chartArea.left,
                  chartInstance.chartArea.bottom - chartInstance.chartArea.top,
                )
              },
              // We need to render number of occurances of moods in one point
              afterDraw: (chartInstance) => {
                const meta = chartInstance.getDatasetMeta(0)
                if (meta.data.length && !_.isEmpty(this.props.moods)) {
                  const { ctx } = chartInstance.chart
                  ctx.font = '14px bold proxima-nova,Helvetica,Roboto,Arial,sans-serif'
                  ctx.textBaseline = 'middle'
                  ctx.textAlign = 'center'
                  _.each(meta.data, (point) => {
                    ctx.fillStyle = '#000'
                    // We need to get the grid value from the pixel value of the point
                    const xValue = Math.round(point._xScale.getValueForPixel(point._model.x))
                    const yValue = Math.round(point._yScale.getValueForPixel(point._model.y))
                    // After that we use the number of moods each point has and render that as text
                    const moods = this.groupedMoodsByOccurance[`${xValue}-${yValue}`]
                    ctx.fillText(moods.length, point._model.x, point._model.y)
                  })
                }
              },
              afterInit: (chartInstance) => {
                if (this.props.allowDragging) {
                  chartInstance.chart.canvas.addEventListener(
                    'click',
                    this.handleClick(chartInstance),
                  )
                  chartInstance.chart.canvas.addEventListener('mousedown', this.handleDragStart())
                  chartInstance.chart.canvas.addEventListener('touchstart', this.handleDragStart())
                  chartInstance.chart.canvas.addEventListener('mouseup', this.handleDragEnd())
                  chartInstance.chart.canvas.addEventListener('touchend', this.handleDragEnd())
                  chartInstance.chart.canvas.addEventListener('mousemove', this.handleDragging())
                  chartInstance.chart.canvas.addEventListener('touchmove', this.handleDragging())
                  chartInstance.chart.canvas.addEventListener('mouseleave', this.handleDragEnd())
                  chartInstance.chart.canvas.addEventListener('touchcancel', this.handleDragEnd())
                }
              },
            },
          ]}
          options={{
            onHover: (event, activeElements) => {
              if (activeElements.length > 0) {
                this.setState({ bubbleHovered: true })
              } else if (this.state.bubbleHovered) {
                this.setState({ bubbleHovered: false })
              }
            },
            legend: { display: false },
            tooltips: {
              // Disable the on-canvas tooltip
              enabled: false,
              custom: this.renderCustomTooltip,
            },
            scales: {
              xAxes: defaultAxesOptions(this.props.withoutAxesLabels ? '' : lang.pleasantness),
              yAxes: defaultAxesOptions(this.props.withoutAxesLabels ? '' : lang.energy),
            },
          }}
        />
      </div>
    )
  }
}

export default MoodMeterChart
